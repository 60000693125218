import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from '../../components/icons/IconProps'

export const ExpandedIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx('expanded-icon', props.className)}
  >
    {/* <path d="M0 0h24v24H0z" fill="none" /> */}
    <path d="M19 13H5v-2h14v2z" />
  </svg>
))
