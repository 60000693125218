import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from '../../components/icons/IconProps'

export const CollapsedIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={cx('expanded-icon', props.className)}
  >
    {/* <path d="M0 0h24v24H0z" fill="none" /> */}
    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
  </svg>
))
