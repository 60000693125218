import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './assets/styles/all.scss'
import App from './App'
import { configureStore } from './store/configureStore'
import saga from './state/saga'

const store = configureStore()
store.runSaga(saga)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)
