import React, { memo } from 'react'
import { PageHeader } from '../../components/app/PageHeader'
import { PaperPlaneIcon } from '../../components/icons/PaperPlaneIcon'

export const MessageSentPage = memo(function MessageSentPage() {
  return (
    <div className="page message-sent-page">
      <PageHeader title="Message Sent" icon={<PaperPlaneIcon />} />

      <div className="content section-header">Thank you for contacting us,</div>

      <div className="content paragraph">
        The doctor will respond to your email address the next working day.
      </div>
      <div className="content table-container">
        <table className="appointment-table">
          <tr>
            <td className="text-left">Monday - Sunday</td>
            <td className="text-right">08:00-22:00</td>
          </tr>
        </table>
        <div className="content">
          <div className="footnote">Excluding UK bank holidays</div>
        </div>
      </div>
      <div className="content paragraph mt-1">
        If you would prefer a quicker response,{' '}
        <a href="tel:03453191695" className="call-link">
          call us
        </a>{' '}
        to arrange an appointment, but please let us know you’ve sent us a
        message.
      </div>
      <div className="content paragraph mt-2">
        By using this service you are agreeing to our{' '}
        <a
          className="terms"
          href="https://www.healthhero.com/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          terms & conditions
        </a>
        .{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.healthhero.com/privacy-policy/"
          className="terms"
        >
          {' '}
          Click here for our privacy policy
        </a>
      </div>
    </div>
  )
})
