import React, { memo } from 'react'
import cx from 'clsx'
import { IconProps } from './IconProps'

export const PaperPlaneIcon = memo<IconProps>(props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 38 37"
    className={cx('paper-plane-icon', props.className)}
  >
    <g>
      <g transform="rotate(-38 25 18.5)">
        <path fill="#fff" d="M13.158 19.515L10.5 29.07l25-14z" />

        <path fill="#fff" d="M35.73 12.04l-25-14 2.658 9.556z" />
        <path
          fill="#2cabe1"
          d="M12.868 19.342l22.684-4.356L10.17 28.709zm22.684-7.968L12.868 7.018 10.169-2.35zm-23.336 2.618a.821.821 0 0 0 .825-.817.821.821 0 0 0-.825-.817H8.372l4.097-3.75 19.557 3.75h-16.43a.821.821 0 0 0-.825.817c0 .451.37.817.825.817h16.43l-19.557 3.75-4.097-3.75h3.844zm29.217-.707c0-.007.002-.013.003-.02v-.007l.002-.032.002-.043-.002-.05c0-.01 0-.02-.002-.031v-.008l-.003-.02-.003-.018a.495.495 0 0 0-.002-.013l-.003-.019-.002-.01-.01-.04-.005-.02-.004-.012-.002-.008-.007-.018a.842.842 0 0 0-.011-.032l-.008-.02-.02-.041-.015-.032-.011-.018-.013-.021a.84.84 0 0 0-.048-.07l-.01-.013a.83.83 0 0 0-.06-.068l-.007-.006-.013-.012a.819.819 0 0 0-.146-.108l-.025-.015L9.23-4.72a.83.83 0 0 0-.91.08.815.815 0 0 0-.277.863l3.239 11.243-5.577 5.113-.01.01a.834.834 0 0 0-.034.035l-.023.025a.773.773 0 0 0-.032.04l-.018.024a.835.835 0 0 0-.03.047c-.005.007-.01.014-.013.022a.836.836 0 0 0-.03.057l-.007.015a.808.808 0 0 0-.027.07l-.002.007a.787.787 0 0 0-.034.334.837.837 0 0 0 .034.164l.003.006a.805.805 0 0 0 .026.07l.008.016c.009.02.018.039.029.057l.013.022a.78.78 0 0 0 .03.047l.019.025.031.04.023.024a.834.834 0 0 0 .034.035l.01.01 5.577 5.113-3.24 11.243A.815.815 0 0 0 8.32 31a.828.828 0 0 0 .91.08l31.773-17.178.014-.008.03-.017.034-.022.015-.01a.879.879 0 0 0 .063-.05l.029-.026.006-.006.018-.02a.893.893 0 0 0 .034-.036l.005-.006.007-.01.01-.011a.858.858 0 0 0 .024-.033l.016-.023.016-.025.008-.015.015-.027a.805.805 0 0 0 .019-.039l.014-.031.013-.037.002-.005.007-.02.004-.012.003-.011.004-.015.001-.006.009-.034.001-.009.004-.02.002-.014c0-.007.002-.013.003-.02z"
        />
      </g>
    </g>
  </svg>
))
