import Dexie from 'dexie'
import 'dexie-observable'
import { IDatabaseChange } from 'dexie-observable/api'

export const db = new Dexie('medsol-pwa')

export interface LocalState {
  id: number
  transport?: 'pending'
}

db.version(1).stores({
  consultations: '++id, createdAt',
  contacts: '++id, createdAt',
  allergies: '++id, name, transport, createdAt',
  medicines: '++id, transport, createdAt, workshopId',
  bloodPressure: '++id, createdAt',
  bloodGlucose: '++id, createdAt',
  bodyMassIndex: '++id, createdAt',
  heartRate: '++id, createdAt',
})

const listeners = {
  consultations: new Set<(items: IDatabaseChange[]) => void>(),
  contacts: new Set<(items: IDatabaseChange[]) => void>(),
  allergies: new Set<(items: IDatabaseChange[]) => void>(),
  medicines: new Set<(items: IDatabaseChange[]) => void>(),
  bloodPressure: new Set<(items: IDatabaseChange[]) => void>(),
  bloodGlucose: new Set<(items: IDatabaseChange[]) => void>(),
  bodyMassIndex: new Set<(items: IDatabaseChange[]) => void>(),
  heartRate: new Set<(items: IDatabaseChange[]) => void>(),
}

db.on('changes', function(changes) {
  const byTables = changes.reduce(
    (target, item) => {
      target[item.table as keyof typeof listeners].push(item)
      return target
    },
    {
      consultations: [] as IDatabaseChange[],
      contacts: [] as IDatabaseChange[],
      allergies: [] as IDatabaseChange[],
      medicines: [] as IDatabaseChange[],
      bloodPressure: [] as IDatabaseChange[],
      bloodGlucose: [] as IDatabaseChange[],
      bodyMassIndex: [] as IDatabaseChange[],
      heartRate: [] as IDatabaseChange[],
    },
  )
  if (byTables.consultations.length) {
    listeners.consultations.forEach(listener =>
      listener(byTables.consultations),
    )
  }
  if (byTables.contacts.length) {
    listeners.contacts.forEach(listener => listener(byTables.contacts))
  }
  if (byTables.allergies.length) {
    listeners.allergies.forEach(listener => listener(byTables.allergies))
  }
  if (byTables.medicines.length) {
    listeners.medicines.forEach(listener => listener(byTables.medicines))
  }
  if (byTables.bloodPressure.length) {
    listeners.bloodPressure.forEach(listener =>
      listener(byTables.bloodPressure),
    )
  }
  if (byTables.bloodGlucose.length) {
    listeners.bloodGlucose.forEach(listener => listener(byTables.bloodGlucose))
  }
  if (byTables.bodyMassIndex.length) {
    listeners.bodyMassIndex.forEach(listener =>
      listener(byTables.bodyMassIndex),
    )
  }
  if (byTables.heartRate.length) {
    listeners.heartRate.forEach(listener => listener(byTables.heartRate))
  }
})
// db.open()

export function subscribe(
  table: keyof typeof listeners,
  listener: (items: IDatabaseChange[]) => void,
) {
  listeners[table].add(listener)
  return () => listeners[table].delete(listener)
}
