import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const HeartbeatIcon = memo<IconProps>(function HeartbeatIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 133 101"
      className={props.className}
    >
      <path
        className="fg"
        d="M110.256 52.948L66.502 94.885 22.771 52.97a29.197 29.197 0 0 1-3.951-4.656h17.194l3.616-3.465v12.625l9.555-9.16h5.587l9.629-9.228v24.149l15.566-14.921h5.586l3.618-3.467v12.627l9.555-9.16h15.458a29.135 29.135 0 0 1-3.928 4.633zM13.991 32.682c0-15.796 13.408-28.647 29.89-28.647 7.986 0 15.492 2.98 21.137 8.391l1.485 1.424 1.484-1.424c5.645-5.41 13.15-8.39 21.133-8.39 16.48 0 29.889 12.85 29.889 28.646a27.58 27.58 0 0 1-2.54 11.6H96.986L93.37 47.75V35.127l-9.556 9.155h-5.586L68.6 53.51V29.362l-15.566 14.92h-5.586l-3.618 3.467V35.125l-9.554 9.157H16.534a27.567 27.567 0 0 1-2.543-11.6zM133 44.29h-11.994a31.494 31.494 0 0 0 2.202-11.603C123.208 14.67 107.915.01 89.119.01c-8.42 0-16.375 2.908-22.618 8.23C60.261 2.917 52.305.01 43.881.01c-18.796 0-34.09 14.66-34.09 32.678 0 4.029.758 7.945 2.204 11.603H0v4.026h13.927a32.965 32.965 0 0 0 5.877 7.504l46.698 44.766 46.723-44.79a32.928 32.928 0 0 0 5.85-7.48H133z"
      />
    </svg>
  )
})
