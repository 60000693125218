import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const StethoscopeIcon = memo<IconProps>(function SthetoscopeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 111 122"
      className={props.className}
    >
      <path
        className="fg"
        d="M97.566 117.928c-4.93 0-8.938-3.858-8.938-8.601 0-4.744 4.009-8.602 8.938-8.602 4.93 0 8.942 3.858 8.942 8.602 0 4.743-4.012 8.601-8.942 8.601zM4.319 44.995h4.255C9.66 57.96 20.977 68.192 34.734 68.192c13.756 0 25.075-10.232 26.16-23.197h4.256c-1 13.773-12.033 25.21-26.446 27.02l-1.848.232v6.38h-4.244v-6.38l-1.848-.232c-14.412-1.81-25.446-13.247-26.445-27.02zM99.688 96.81V53.476c0-6.926-5.859-12.562-13.062-12.562-7.204 0-13.064 5.636-13.064 12.562v46.795c0 9.73-8.234 17.647-18.353 17.647-10.12 0-18.354-7.918-18.354-17.647V82.709h4.246v-6.926c16.298-2.912 28.367-16.719 28.367-32.828v-2.041h-4.245V8.389c0-2.24-.906-4.347-2.554-5.933C61.019.872 58.827 0 56.499 0h-.966c-1.172 0-2.123.914-2.123 2.04 0 1.127.951 2.042 2.123 2.042h.965c1.196 0 2.322.448 3.168 1.261a4.197 4.197 0 0 1 1.312 3.046v32.525h-4.245v2.041c0 11.665-9.869 21.156-22 21.156-12.13 0-21.998-9.49-21.998-21.156v-2.041H8.49V8.39a4.2 4.2 0 0 1 1.312-3.047 4.542 4.542 0 0 1 3.169-1.261h.964c1.171 0 2.122-.914 2.122-2.041C16.057.914 15.107 0 13.934 0h-.963c-2.333 0-4.523.873-6.172 2.457C5.152 4.043 4.244 6.15 4.244 8.39v32.525H0v2.041c0 16.11 12.068 29.916 28.366 32.828v6.926h4.245v17.562C32.61 112.253 42.749 122 55.21 122c12.46 0 22.597-9.747 22.598-21.729V53.476c0-4.676 3.956-8.48 8.82-8.48 4.86 0 8.815 3.804 8.815 8.48V96.81c-6.263.98-11.06 6.215-11.06 12.512 0 6.99 5.913 12.678 13.183 12.678 7.271 0 13.187-5.688 13.187-12.678 0-6.297-4.8-11.533-11.065-12.512z"
      />

      <path
        className="fg"
        d="M97.454 111.7c-1.418 0-2.572-1.11-2.572-2.472 0-1.364 1.154-2.473 2.572-2.473 1.42 0 2.577 1.11 2.577 2.473 0 1.362-1.157 2.472-2.577 2.472zm0-8.99c-3.741 0-6.784 2.925-6.784 6.518 0 3.591 3.043 6.516 6.784 6.516 3.743 0 6.789-2.925 6.789-6.516 0-3.593-3.046-6.518-6.79-6.518z"
      />
    </svg>
  )
})
