import React, { memo } from 'react'
import { IconProps } from './IconProps'

export const MedicalHistoryIcon = memo<IconProps>(function HeartbeatIcon(
  props,
) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 89 117"
      className={props.className}
    >
      <path
        className="fg"
        d="M74.959 50.248H60.006L53.36 35.967c-.554-1.058-1.384-1.587-2.492-1.587s-2.215.529-2.492 1.587L36.192 62.413 30.93 51.835c-.553-.794-1.384-1.587-2.492-1.587H14.04c-1.661 0-2.769 1.058-2.769 2.644 0 1.587 1.108 2.645 2.769 2.645H26.5l7.2 14.281c.553.793 1.384 1.587 2.492 1.587s1.938-.53 2.492-1.587l12.184-26.182 4.984 10.579c.277.793 1.385 1.322 2.493 1.322h16.614c1.661 0 2.769-1.058 2.769-2.645 0-1.586-1.108-2.644-2.77-2.644z"
      />

      <path
        className="fg"
        d="M80.493 7.934H63.88c0-1.587-1.108-2.645-2.769-2.645h-6.923c-1.107 0-1.938-.529-2.215-1.058C50.31 1.587 47.542 0 44.495 0c-3.046 0-5.815 1.587-7.2 4.231-.553.53-1.384 1.058-2.492 1.058h-6.922c-1.662 0-2.77 1.058-2.77 2.645v13.223c0 1.587 1.108 2.645 2.77 2.645H61.11c1.661 0 2.769-1.058 2.769-2.645s-1.108-2.644-2.77-2.644H30.65v-7.935h4.154c3.046 0 5.815-1.586 7.2-3.967 1.108-1.85 3.877-1.586 4.984 0 1.385 2.38 4.154 3.967 7.2 3.967h4.154c0 1.587 1.108 2.645 2.769 2.645h19.383c1.662 0 2.77 1.058 2.77 2.645v92.562c0 1.586-1.108 2.644-2.77 2.644H8.497c-1.661 0-2.769-1.058-2.769-2.644V15.868c0-1.587 1.108-2.645 2.77-2.645h5.537c1.662 0 2.77-1.058 2.77-2.644 0-1.587-1.108-2.645-2.77-2.645H8.497C3.79 7.934.19 11.372.19 15.868v92.562c0 4.496 3.6 7.934 8.307 7.934h71.996c4.708 0 8.307-3.438 8.307-7.934V15.868c0-4.496-3.6-7.934-8.307-7.934z"
      />

      <path
        className="fg"
        d="M50.037 84.63H14.04c-1.662 0-2.769 1.058-2.769 2.645 0 1.586 1.108 2.644 2.769 2.644h35.998c1.662 0 2.77-1.058 2.77-2.644 0-1.587-1.108-2.645-2.77-2.645z"
      />

      <path
        className="fg"
        d="M33.423 95.21H14.039c-1.662 0-2.769 1.058-2.769 2.645 0 1.586 1.108 2.644 2.769 2.644h19.384c1.661 0 2.769-1.058 2.769-2.644 0-1.587-1.108-2.645-2.77-2.645z"
      />
    </svg>
  )
})
