import React, { memo } from 'react'

import { PageHeader } from '../../components/app/PageHeader'
import { useAccordion } from '../../components/accordion/useAccordion'
import { AccordionPane } from '../../components/accordion/AccordionPane'
import { ExpandedIcon } from './ExpandedIcon'
import { CollapsedIcon } from './CollapsedIcon'
import medsolLogo from '../../assets/images/HealthHeroLogo.svg'

export const HowItWorksPage = memo(function HowItWorksPage() {
  const { isExpanded, toggle } = useAccordion({
    panes: [
      'booking',
      'prescriptions',
      'referrals',
      'fit-notes',
      'ms',
      'our-gp',
      'quality-control',
    ],
  })
  return (
    <div className="page how-it-works-page">
      <PageHeader
        title="How it works"
        icon={
          <svg
            width="72px"
            height="72px"
            viewBox="0 0 72 72"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-49.000000, -42.000000)" stroke="#2CABE1">
                <g id="Group-3" transform="translate(50.000000, 43.000000)">
                  <g transform="translate(15.000000, 15.000000)">
                    <circle id="Oval" cx="20.5" cy="20.5" r="20"></circle>
                    <circle id="Oval" cx="20.5" cy="9.5" r="2.5"></circle>
                    <rect
                      id="Rectangle"
                      x="18"
                      y="16"
                      width="5"
                      height="19"
                      rx="2.5"
                    ></rect>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        }
      />
      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('booking') ? ExpandedIcon : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('booking')
          },
        }}
        name="booking"
        title="Booking a consultation"
        expanded={isExpanded('booking')}
      >
        <div className="question">Who will I speak to when I call? </div>
        <div className="answer">
          One of our specially trained operators will answer your call, take
          some details and arrange a convenient time for a GP to call you back,
          or email you a link to join if you requested a video consultation.
        </div>
        <div className="question"> What sort of things can I ask about? </div>
        <div className="answer">
          Anything you would ask your own GP, for example, gastroenterological
          issues, ears, nose and throat complaints, dermatological conditions,
          aches and pains and explanations or second opinions on diagnosis or
          treatment.
        </div>
        <div className="question">
          Can I choose to speak to a male or female doctor?{' '}
        </div>
        <div className="answer">
          Yes. Where possible, we will always try to accommodate a patient’s
          preference to speak to a male or female doctor.
        </div>
        <div className="question">
          Is there a limit on the number of consultations I can have?
        </div>
        <div className="answer">
          No, you can contact the GP advice line as often as needed, there is no
          limit to phone, video or message consultations.
        </div>
        <div className="question">Will I still need to see a GP?</div>
        <div className="answer">
          Most patients receive the advice, reassurance and, where appropriate,
          diagnosis they need from our doctors, however, if your symptoms
          require a physical examination, or a repeat prescription, you may be
          referred to your own GP. The service is not a replacement for your own
          GP.
        </div>
        <div className="question">
          Can I have a consultation for my children too?
        </div>
        <div className="answer">
          Dependants are eligible to use the GP advice line. Where a patient is
          under the age of 16, it is advisable for their legal guardian to call
          on their behalf.
        </div>
        <div className="question">Who can use the service?</div>
        <div className="answer">
          You must be a resident and/or national of the United Kingdom, its
          Crown Dependencies or the Republic of Ireland to be eligible to use
          our services. By accessing and using our services, you are confirming
          that you are eligible. We are unable to provide service if you are an
          Irish female resident and you are or suspect that you may be pregnant.
        </div>
        <div className="question">Can I have a translator?</div>
        <div className="answer">
          If you require translation services for your GP consultation, ask your
          Customer Service Team Member at the point of booking. We offer a
          translator to join your consultation at no extra charge with more than
          240 languages available.
        </div>
        <div className="question">Can I call in emergencies? </div>
        <div className="answer">
          <div>
            You must not use the service for emergencies or urgent conditions as
            this may delay necessary treatment.
          </div>
          <div className="mt-1 mb-1">
            If you believe that you are in an emergency or life-threatening
            situation you should contact your local emergency services
          </div>
          immediately, especially if someone: <br />
          - has difficulty or is not breathing <br />
          - you believe someone is having a heart attack or stroke <br />
          - has severe chest pain <br />
          - has severe abdominal pain <br />
          - has severe bleeding and it can’t be stopped <br />
          - has lost consciousness <br />- is in an acute confused state and/or
          is having fits which aren’t stopping You may still need to see your
          own GP or contact the emergency services if the clinician you speak
          with feels it is necessary.
        </div>
      </AccordionPane>

      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('prescriptions')
            ? ExpandedIcon
            : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('prescriptions')
          },
        }}
        name="prescriptions"
        title="Private prescriptions"
        expanded={isExpanded('prescriptions')}
      >
        <div className="question">Can the GPs issue a prescription? </div>
        <div className="answer">
          Where the GP believes it is clinically appropriate, they can issue
          private prescriptions. The medication can either be collected from a
          nominated pharmacy or delivered to a nominated address. Since these
          are private prescriptions, the pharmacy will charge for the
          medication. For delivery, there is a charge for postage and packaging.
          The online pharmacy will contact you directly to take payment.
        </div>
        <div className="question">Can the GPs issue repeat prescriptions? </div>
        <div className="answer">
          <div className="mb-1">
            In line with clinical best practice guidelines for remote
            prescribing, our GPs are unable to issue long term, repeat
            medication. Should you require repeat prescription medication, it is
            in your best interests to be seen by your regular GP.
          </div>
          <div>
            Our GPs can offer advice on medication you are taking and may be
            able to offer short course medication depending on the symptoms
            presented.
          </div>
        </div>
        <div className="question">
          What are the prescription medication delivery charges and how long
          does it take?
        </div>
        <div className="answer">
          <div>
            If you are in the UK, Crown Dependencies or EU at the time of your
            consultation and are offered a prescription, then provided it is in
            stock and the prescription is raised before 4pm (UK time), it can be
            delivered to an address of your choice as soon as the next working
            day.
          </div>
          <div className="mt-1">
            The pharmacy will contact you by telephone shortly after your
            consultation. A range of delivery speeds are available which they
            will discuss with you at the time. The total cost will depend on
            your location and chosen delivery option. Next day delivery to the
            Crown Dependencies or EU destinations cannot be guaranteed and may
            be affected by local customs regulations.
          </div>
          <div className="mt-1">
            Whilst the cost of medication varies, there is a minimum charge of
            £6.50 per item for postage.
          </div>
          <div className="mt-1">
            There is no P&P for collection of prescription medication at local
            pharmacies. Medication charges will vary between pharmacies.
          </div>
        </div>
        <div className="question">
          Can I have my medication delivered abroad?
        </div>
        <div className="answer">
          <div>
            Inside the European Union: If you are in an EU country other than
            the Republic of Ireland at the time of your consultation and the GP
            believes you would benefit from medication, they will explore
            alternative treatment options such as over the counter medicines
            from a local pharmacy as a first consideration.
          </div>
          <div className="mt-1">
            If over the counter medicines are not suitable and the duration of
            your stay abroad allows for the medication to be delivered to you
            safely, it can be delivered to a nominated overseas address.
            However, if you are nearing the end of your stay abroad, it may be
            more convenient to have the medication delivered to your home
            address on your return.
          </div>
          <div className="mt-1">
            Please note, the cost of overseas postage will be higher than UK
            delivery, therefore seeing a local GP may be the preferred option.
          </div>
          <div className="mt-1">
            Outside the EU: Due to pharmaceutical laws and regulations we are
            unable to prescribe if you are outside of the United Kingdom, Crown
            Dependencies or the EU at the time of your consultation.
          </div>
        </div>
        <div className="question">How is the medication packaged?</div>
        <div className="answer">
          It depends on the size and type of medication, but most smaller items
          are sent in cushioned, securely sealed packets. Packets or boxes are
          discrete with no markings.
        </div>
        <div className="question">
          What safeguarding measures are in place for private prescriptions?
        </div>
        <div className="answer">
          <div>
            Experienced, qualified, practising GPs - All HealthHero GPs are
            experienced practising doctors who, as in their NHS role, adhere to
            the GMC guidelines on remote prescribing.
          </div>
          <div className="mt-1">
            Checked by multiple, qualified physicians The GP raises the
            prescription, it is then checked by a qualified pharmacist prior to
            being dispensed
          </div>
          <div className="mt-1">
            A full medical history is taken – During the call, the GPs takes a
            full medical history including any current medication and dosages,
            allergies, etc. This detail remains on the patient management
            system, including data relating to any medication prescribed by the
            HealthHero doctor, so in future consultations, doctors can see
            previous prescribing history.
          </div>
          <div className="mt-1">
            Consultation notes are sent to the patient’s own GP - When a
            prescription is issued, the consultation notes are sent to the
            patient’s own GP with patient consent.
          </div>
        </div>
        <div className="question">
          What happens if I don’t have internet, can I still have a private
          prescription over the phone?
        </div>
        <div className="answer">
          Yes, our telephone consultation service provides 24/7 telephone access
          to a GP, and should the doctor feel it is necessary, they can remotely
          issue private prescription medication and open referrals.
        </div>
      </AccordionPane>

      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('referrals') ? ExpandedIcon : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('referrals')
          },
        }}
        name="referrals"
        title="Private referrals"
        expanded={isExpanded('referrals')}
      >
        <div className="question">
          Can the GPs issue referrals to specialists?{' '}
        </div>
        <div className="answer">
          <div>
            Should the GP feel that you would benefit from seeing a consultant
            or specialist, they can provide you with an open private referral
            letter. This referral letter cannot be used to access NHS services
            in the UK. Referral letters can be posted or emailed directly
            according to your preference.
          </div>
          <div className="mt-1">
            If you have private medical insurance you will need to contact your
            insurer before engaging a consultant or specialist, or accessing any
            diagnostic or treatment services, unless you are self-funding. The
            referral letter is not a private medical insurance claims
            authorisation.
          </div>
        </div>
      </AccordionPane>
      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('fit-notes') ? ExpandedIcon : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('fit-notes')
          },
        }}
        name="fit-notes"
        title="Private fit notes"
        expanded={isExpanded('fit-notes')}
      >
        <div className="question">Can the GPs issue fit notes?</div>
        <div className="answer">
          If it is clinically appropriate and if the service is available to
          you, the clinician can issue a private fit note. HealthHero does not
          charge for issuing private fit notes, but you will need to check with
          your employer to confirm that they will accept one. Private fit notes
          cannot be used to access state funded sick pay or benefits.
        </div>
      </AccordionPane>
      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('ms') ? ExpandedIcon : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('ms')
          },
        }}
        name="ms"
        title="About HealthHero"
        expanded={isExpanded('ms')}
      >
        <div style={{ textAlign: 'center' }}>
          <img
            src={medsolLogo}
            style={{ width: '100%', height: 'auto', maxWidth: '310px' }}
            alt="HealthHero Logo"
          />
        </div>

        <div className="question mt-2">
          This service is provided by HealthHero, specialists in 24/7 Private GP
          services.
        </div>
        <div className="answer">
          <div>
            HealthHero is built upon the powerful combination of digital knowhow
            and telehealth expertise with over 20 years’ experience in
            connecting members with doctors remotely.
          </div>
          <div className="mt-1">
            All of our GPs are experienced, practising doctors who have exactly
            the same qualifications as your NHS GP. We select our doctors
            carefully and engage with them personally at HealthHero.
          </div>
        </div>
      </AccordionPane>

      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('our-gp') ? ExpandedIcon : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('our-gp')
          },
        }}
        name="booking"
        title="Our GP & quality  control"
        expanded={isExpanded('our-gp')}
      >
        <div className="question">
          How do you recruit your GPs? What qualifications do the doctors have?{' '}
        </div>
        <div className="answer">
          Our doctors are recruited via a rigorous two stage interview process
          with our Chief Medical Officer and HR vetting procedures, which ensure
          that GPs are qualified, experienced, and knowledgeable to an
          equivalent standard to your own GP. Our minimum criteria requires that
          our GPs:
          <br />
          <br />
          - are currently practising in the NHS as a GP and have at least 2
          years’ experience
          <br /> <br />
          - are on The GMC register
          <br /> <br />
          - have a Bachelor of Medicine Degree, those who qualified after 2007
          must be Members of the Royal College of GPs
          <br /> <br />
          - are on the National Performers List for England
          <br /> <br />
          - complete an annual NHS appraisal
          <br /> <br />
          - have an enhanced DBS
          <br /> <br />
          - background check
          <br /> <br />
          - provide complete document evidence that they are suitably qualified
          <br /> <br />
          - provide at least 2 references to support their skills and
          experiences
          <br /> <br />
        </div>
        <div className="question">
          How do you ensure the GPs are vetted on an ongoing basis?
        </div>
        <div className="answer">
          <div>
            Once recruited, the GPs are continuously monitored to ensure they
            maintain their skills to the highest standards.
          </div>
          <div className="mt-1">
            All our GPs complete an annual NHS appraisal to review their
            practise and performance.
          </div>
          <div className="mt-1">
            Our Quality Management System incorporates policies and procedures,
            consistent with best NHS practise, and each GP’s performance is
            continually audited. This auditing includes reviewing key documents
            to ensure all GP's knowledge, skills and registration are up to
            date.
          </div>
          <div className="mt-1">
            We hold bi-annual doctor meetings with our clinical team to maintain
            good engagement, share best practice and help to continually raise
            our standards of care and service. In addition to ongoing internal
            auditing, our GPs' clinical notes are also reviewed by an
            independent external organisation called Clinical Guardian.
          </div>
        </div>
        <div className="question">
          Does HealthHero meet Clinical Quality Commission standards?
        </div>
        <div className="answer">
          <div>
            Yes, HealthHero is regulated by the Care Quality Commission for the
            delivery of medical services, in the “Digital Healthcare” category.
          </div>
          <div className="mt-1">
            HealthHero most recent CQC inspection took place in June 2019. The
            inspection was conducted by a GP, Clinical Pharmacist and CQC
            Registered Inspector who provided an overall rating of "Good".
          </div>
        </div>
        <div className="question">What is the CQC?</div>
        <div className="answer">
          <div>
            The Care Quality Commission monitors, inspects and regulates
            hospitals, care homes, GP surgeries, dental practices, and other
            care services to make sure they meet fundamental standards of
            quality and safety.
          </div>
          <div className="mt-1">
            To get to the heart of patients’ experiences of care and treatment,
            CQC evaluates service providers across five categories relating to
            safety, effectiveness, caring, responsiveness to patient need and
            leadership.
          </div>
        </div>
      </AccordionPane>

      <AccordionPane
        headerProps={{
          className: 'faq',
          // @ts-ignore
          iconComponent: isExpanded('quality-control')
            ? ExpandedIcon
            : CollapsedIcon,
          // @ts-ignore
          onClick: () => {
            toggle('quality-control')
          },
        }}
        name="booking"
        title="Data protection and confidentiality"
        expanded={isExpanded('quality-control')}
      >
        <div className="question">How are data and records stored?</div>
        <div className="answer">
          All patient data and records are stored on our patient management
          system, in a secure data centre which is only accessible with
          multi-factor authentication by authorised personnel.
        </div>
        <div className="question">Is the service confidential?</div>
        <div className="answer">
          Yes. Patient confidentiality is very important, any records remain
          confidential. Our doctors follow clear guidelines about sharing
          clinical notes with the patient’s own GPs; when appropriate and with
          patient explicit consent, we send any relevant medical notes,
          including consultations notes for any prescriptions or referrals
          issued, to the patient’s own GP.
        </div>
        <div className="question">
          How long will my personal data be held on the systems?
        </div>
        <div className="answer">
          Clinical records are held in accordance with the NHS recommended
          retention periods for general practice and telemedicine records. In
          all cases, records are be maintained for a minimum of ten years.
        </div>
      </AccordionPane>
    </div>
  )
})
