import React from 'react'
import { Switch, Route } from 'react-router'
import { HomePage } from './pages/Home/HomePage'
import { ScreenRoutes } from './types/ScreenRoutes'
import { GpConsultationsPage } from './pages/GpConsultations/GpConsultationsPage'
import { EConsultationsPage } from './pages/EConsultations/EConsultationsPage'
import { MessageDrPage } from './pages/MessageDr/MessageDrPage'
import { RequestAppointmentPage } from './pages/RequestAppointment/RequestAppointmentPage'
import { MessageSentPage } from './pages/MessageSent/MessageSentPage'
import { RequestConfirmedPage } from './pages/RequestConfirmed/RequestConfirmedPage'
import { MyHealthPage } from './pages/MyHealth/MyHealthPage'
import { HealthInformationPage } from './pages/HealthInformation/HealthInformationPage'
import { ServicesNearPage } from './pages/ServicesNear/ServicesNearPage'
import { HowItWorksPage } from './pages/HowItWorks/HowItWorksPage'

const App = () => {
  return (
    <div className="App">
      <Switch>
        <Route path={ScreenRoutes.GP_CONSULT}>
          <GpConsultationsPage />
        </Route>
        <Route path={ScreenRoutes.E_CONSULT}>
          <EConsultationsPage />
        </Route>
        <Route path={ScreenRoutes.MESSAGE_DR}>
          <MessageDrPage />
        </Route>
        <Route path={ScreenRoutes.REQUEST_APPOINTMENT}>
          <RequestAppointmentPage />
        </Route>
        <Route path={ScreenRoutes.MESSAGE_SENT}>
          <MessageSentPage />
        </Route>
        <Route path={ScreenRoutes.REQUEST_CONFIRMED}>
          <RequestConfirmedPage />
        </Route>
        <Route path={ScreenRoutes.MY_HEALTH}>
          <MyHealthPage />
        </Route>
        <Route path={ScreenRoutes.HEALTH_INFORMATION}>
          <HealthInformationPage />
        </Route>
        <Route path={ScreenRoutes.SERVICES_NEAR_YOU}>
          <ServicesNearPage />
        </Route>
        <Route path={ScreenRoutes.HOW_IT_WORKS}>
          <HowItWorksPage />
        </Route>
        <Route>
          <HomePage />
        </Route>
      </Switch>
    </div>
  )
}

export default App
