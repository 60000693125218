import React, { memo } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { StethoscopeIcon } from '../../components/icons/StethoscopeIcon'
import { MessageIcon } from '../../components/icons/MessageIcon'
import { PhoneConsultationIcon } from '../../components/icons/PhoneConsultationIcon'
import { ScreenRoutes } from '../../types/ScreenRoutes'
import { PageHeader } from '../../components/app/PageHeader'

export const GpConsultationsPage = memo(function GpConsultationsPage() {
  const history = useHistory()
  return (
    <div className="page gp-consultations-page ">
      <PageHeader
        title="GP Consultations"
        icon={<StethoscopeIcon className="stethoscope-icon" />}
      />

      <div className="content">
        <div className="section-header">
          Speak to a doctor at a time and place that suits you
        </div>
      </div>

      <div className="speak-to-doctor-section">
        <div className="speak-to-doctor-section-background" />
        <div className="button-tile-container pattern-bg">
          <div className="button-tile button-tile-secondary mr-1">
            <Link className="button-tile-link" to={ScreenRoutes.MESSAGE_DR}>
              <div className="button-container">
                <div className="icon-container">
                  <MessageIcon className="button-icon" />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexBasis: 'auto',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <button className="btn">Message Dr.</button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="button-tile button-tile-primary request-appointment-btn">
            <Link
              className="button-tile-link"
              to={ScreenRoutes.REQUEST_APPOINTMENT}
            >
              <div className="button-container">
                <div className="icon-container">
                  <PhoneConsultationIcon noShadow />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexBasis: 'auto',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <button className="btn">Request an appointment</button>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="section-header mt-1">Have a question?</div>
        <div className="content">
          <div className="paragraph">
            Read our FAQ below for more details on booking a consultation,
            private prescriptions, and more :
          </div>
          <br />
          <br />
          <div className="flex-center mt-2">
            <a
              target="_blank"
              href="https://www.healthhero.com/private-prescription-service/"
            >
              <button
                onClick={e => {
                  e.preventDefault()
                  history.push(ScreenRoutes.HOW_IT_WORKS)
                }}
                style={{ minWidth: '250px' }}
                className="btn btn-primary-1"
              >
                How it works
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="content section">
        <div className="paragraph content terms-and-conditions">
          By using this service you are agreeing to our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.healthhero.com/terms-and-conditions/"
          >
            terms and conditions
          </a>
          .{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.healthhero.com/privacy-policy/"
          >
            Click here for our privacy policy
          </a>
        </div>
      </div>
    </div>
  )
})
